import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import { Footer } from '../components/footer'
import image1 from './img/anons/image1.jpeg'
import image2 from './img/anons/image2.jpeg'
import image3 from './img/anons/image3.jpeg'
import image4 from './img/anons/image4.jpeg'
import image5 from './img/anons/image5.png'

const Div = styled.div`
  .hero {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 61.8vh;
    max-height: 600px;

    text-align: center;
  }

  h1 {
    font-family: 'GT Walsheim Pro Bold';
  }

  .heroImage {
    position: relative;
    height: 600px;
    width: 600px;
    max-height: 450px;
  }

  @media (max-width: 600px) {
    .heroImage {
      width: 100%;
    }
  }
`

class Anonses extends React.Component {
  render() {
    return (
      <Layout location="/anonses">
        <Div
          style={
            {
              // background: '#f2f1e2',
              // borderRadius: 5,
              // boxShadow: '15px 10px 40px -2px rgba(0,0,0,.8)',
            }
          }
        >
          <Helmet title="Анонсы" />
          <div className="wrapper">
            <h1 className="section-headline">Анонсы</h1>
            <img src={image1} alt="анонс" />
            <img src={image2} alt="анонс" />
            <img src={image3} alt="анонс" />
            <img src={image4} alt="анонс" />
            <img src={image5} alt="анонс" />
          </div>
        </Div>
        <Footer text="При финансовой поддержке Российского фонда культуры в рамках проекта № 21-3-000455 «Волонтёрская деятельность в сфере сохранения и популяризации культурного наследия: лучшие практики и инициативы»" />
      </Layout>
    )
  }
}

export default Anonses
